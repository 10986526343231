<template>
  <a-modal
    :visible="value"
    title="复制经销商分组"
    :mask-closable="false"
    @cancel="handleCancel"
  >
    <a-form-model
      class="form"
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ flex: '0 0 140px' }"
      :wrapper-col="{ flex: '0 0 calc(100% - 140px)'}"
    >
      <a-form-model-item class="mb-0" label="待复制经销商分组" prop="copyGroupName">
        {{ form.copyGroupName }}（{{ form.copyGroupType === 1 ? '公共分组' : '私有分组' }}）
      </a-form-model-item>
      <a-form-model-item class="mb-0" label="包含经销商" prop="b">
        {{ form.copyGroupDealerCount }}
      </a-form-model-item>
      <a-form-model-item class="mb-0" label="新分组类型" prop="b">
        {{ form.copyGroupType === 1 ? '公共分组' : '私有分组' }}
      </a-form-model-item>
      <a-form-model-item label="分组名称" prop="groupName">
        <a-input
          v-model="form.groupName"
          allow-clear
          placeholder="请输入分组名称"
        />
      </a-form-model-item>
      <a-form-model-item label="负责人" prop="leaders">
        <a-button type="primary" @click="handleStaffSelect('leaders')">添加/编辑员工</a-button>
        <div class="tag-wrap">
          <a-tag v-for="item in form.leaders" :key="item.id" color="blue">
            {{ item.name }}
          </a-tag>
        </div>
      </a-form-model-item>
      <a-form-model-item label="协作人" prop="coordinators">
        <a-button type="primary" @click="handleStaffSelect('coordinators')">添加/编辑员工</a-button>
        <div class="tag-wrap">
          <a-tag v-for="item in form.coordinators" :key="item.id" color="blue">
            {{ item.name }}
          </a-tag>
        </div>
      </a-form-model-item>
    </a-form-model>
    <template slot="footer">
      <div class="btn-wrap">
        <div class="btn-wrap__left">
          <a>提示：你可以在复制分组后再调整分组內的经销商。</a>
        </div>
        <div class="btn-wrap__right">
          <a-button @click="handleCancel">
            取消
          </a-button>
          <a-button type="primary" :loading="confirmLoading" @click="handleConfirm">
            确定
          </a-button>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: 'CopyDealerGroupModal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default() {
        return {
          copyGroupId: '',
          copyGroupName: '',
          copyGroupType: '',
          copyGroupDealerCount: '',
          groupName: '',
          leaders: [
            // { id: '', name: '' }
          ],
          coordinators: [
            // { id: '', name: '' }
          ]
        }
      }
    },
    groupList: {
      type: Array,
      default() {
        return []
      }
    },
    confirmLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules: {
        groupName: [
          { required: true, message: '请输入分组名称', trigger: 'blur' }
        ],
        leaders: [
          { type: 'array', required: true, message: '请选择负责人', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleStaffSelect(type) {
      this.$emit('staff-select', type)
    },
    handleCancel() {
      this.$emit('input', false)
      this.$emit('cancel')
      this.resetForm()
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('confirm', { ...this.form })
        } else {
          return false
        }
      }) 
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.form.copyGroupId = ''
      this.form.copyGroupName = ''
      this.form.copyGroupType = ''
      this.form.copyGroupDealerCount = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  display: flex;
}

.mb-0 {
  margin-bottom: 0;
}

.btn-wrap {
  display: flex;
  align-items: center;

  &__left {
    flex: 1;
    margin-right: 8px;
    text-align: left;
  }
}
</style>
